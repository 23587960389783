.selected-row {
    background-color: #f2f2f2; /* or any color you prefer */
}
.selected-row td:first-child {
    background: url("../../../public/favicon.png") center center no-repeat;
    background-size: contain;
}

.price {
    letter-spacing: 1px;
}


.class-title {
    cursor: pointer;
    font-size: 2rem;
    font-weight: 800;
}


tr.class-group {
    cursor: pointer;
    transition: background-color 0.4s ease-in-out;
}
  
tr.class-group:hover,
tr.class-group.select{
    background-color: #f651ac4a;
}

tr.enrolled {
    background-color: #00c82b4a !important;
}


.students-enrolled {
    display: block;
    padding: 12px 4px;
    text-align: center;
}

.class-schedule.mobile .table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    display: table;
    margin-bottom: 0;
    max-width: 100%;
    border: 1px solid #9b9191;
}

.mobile {
    .students-enrolled {
        display: table-row;
        padding: 0;
        text-align: left;
    }
    &.class-schedule .class-price {
        padding: 4px;
    }
    &.class-schedule .class-total-price {
        padding: 4px;
    }
}

.class-schedule .table > :not(caption) > * > * {
    background-color: inherit;
}

.class-schedule .table, .class-schedule .table th, .class-schedule .table tr, .class-schedule .table td {
    border: 1px solid #9b9191;
}
.class-schedule .table th {
    text-align: center;
}
.class-schedule .class-price {
    padding: 18px;
}
.class-schedule .class-location {
    padding: 18px 9px;
}
.class-schedule .class-enrolled {
    min-width: 108px;
}
.class-schedule .class-total-price {
    padding: 18px;
    min-width: 96px;
}

.enrolling-icon {
    height: 40px
}


.classes-header {
    width: 20%;
}

// mobile size
@media (max-width: 768px) {
    .classes-header {
        width: auto;
    }
}

.edu-enroll .dropdown-toggle, .edu-enroll .dropdown-toggle:active {
    background-color: #bd328a;
    border-color: #bd328a;
    display: block;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
    margin: 8px auto;
    padding: 8px;
    min-width: 94px;
}

.edu-enroll .mobile .class-wrapper {
    padding-bottom: 60px;
}

.edu-enroll .checkout-error b{
    color: red;
}