.wesworld-wrapper {
    background-image: url('./wesworld-bg-1.jpg');
    background-size: cover;
    height: 100%;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    width: 100%;
}
.wesworld-wrapper h1{
    color: #fff;
}

.wesworld-wrapper .feature-title {
    color: green;
}
.wesworld-wrapper .feature-description {
    color: white;
}
.wesworld-wrapper .mainmenu > li > a {
    color: white !important;
}

// media queries
@media (max-width: 767px) {
    .wesworld-wrapper {
        background-image: url('./wesworld-bg-1.jpg');
        background-size: cover;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.wesworld-wrapper .popup-mobile-menu ul li a {
    color: var(--color-body) !important;
}