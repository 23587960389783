/*-------------------------
    Calendar 
---------------------------*/


.calendar {
    padding: 10px;
    table {
        margin: 0;
    }
}