/* Add this to your CSS or style file */
.video-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Dark semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .video-popup.active {
    opacity: 1;
    visibility: visible;
  }
  
  .video-popup-inner {
    max-width: 800px; /* Adjust as needed */
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
  }
  
  .video-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 20px;
    color: #fff;
  }
  
  .iframe-video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (change as needed) */
  }
  
  .iframe-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  