.my-account-area {
    ul {
        li {
            list-style-type: none;
            font-size: 25px;
            padding: 10px;
            transition: background-color 0.5s ease-in-out; /* Add transition property for opacity */
        }

        li:hover {
            background-color: #cc338a;
            color: white;
        }
    }
}
